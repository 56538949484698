@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500&display=swap');

@font-face {
    font-family: 'sodo_sansbold';
    src: url('../fonts/sodo/sodosans-bold-webfont.woff2') format('woff2'), url('../fonts/sodo/sodosans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sodo_sansitalic';
    src: url('../fonts/sodo/sodosans-italic-webfont.woff2') format('woff2'), url('../fonts/sodo/sodosans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sodo_sansregular';
    src: url('../fonts/sodo/sodosans-regular-webfont.woff2') format('woff2'), url('../fonts/sodo/sodosans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sodo_sanssemibold';
    src: url('../fonts/sodo/sodosans-semibold-webfont.woff2') format('woff2'), url('../fonts/sodo/sodosans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
/* Sodo */
body {
    font-family: 'sodo_sansregular';
    color: #000000;
    background-color: #ffead2;
}

    body.enrollment {
        font-family: 'sodo_sansregular';
        color: #000000;
        background-color: #eef0ef;
    }

.fontsodo-bold {
    font-family: 'sodo_sansbold';
}

.fontsodo-regular {
    font-family: 'sodo_sansregular';
}

.fontsodo-semibold {
    font-family: 'sodo_sanssemibold';
}

.fontsodo-italic {
    font-family: 'sodo_sansitalic';
}

.fontkanit {
    font-family: 'Kanit', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.lead {
    color: #000000;
}

.text-red {
    color: #FF0000;
}

.text-gray {
    color: #cccccc;
}

.text-pink {
    color: #e09c9e;
}

.text-green {
    color: #00754A;
}

a.text-green:hover {
    color: #00754A;
}

a.text-black:hover {
    color: #000000;
}

.text-housegreen {
    color: #1E3932;
}

.text-starbucks-green {
    color: #006241;
}

.text-blue {
    color: #001b71;
}

.text-blue-50 {
    color: #1ec3fb;
}

.text-blue-2 {
    color: #606583;
}

.text-label {
    color: #231F20;
}

.text-underline {
    text-decoration: underline;
}

#root, #root > div {
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
}

.container {
    max-width: 960px;
    padding: 0px;
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
}

.text-blue {
    color: #001b71;
}

.radius-form-5 input.form-control,
.radius-form-5 select.form-control {
    border-radius: 5px;
}

.border-right-2 {
    border-right: 2px solid #041a72;
}

.fs14 {
    font-size: 14px;
}

.img-star-stamp {
    width: 85px;
    margin-top: -5px;
}

.text-star-stamp {
    margin-left: -15px;
    margin-top: 20px;
}

.text-use-coupon {
    margin-top: 32%;
    margin-left: -15px;
    margin-right: -15px;
}

.text-use-coupon2 {
    margin-top: 2px;
    margin-left: 0px;
    margin-right: -15px;
    min-height: 55px;
}

.text-datetime-1 {
    font-size: 100%;
    color: #E2FB1F;
    font-weight: 400;
}

.text-datetime-2 {
    font-size: 110%;
    color: #ffffff;
    font-weight: 500;
}

.text-datetime-3 {
    font-size: 100%;
    color: #E2FB1F;
    font-weight: 400;
}

.text-head-mycoupon {
    font-size: 1.6rem;
}

.text-after-stamp {
    margin-left: -25px;
    margin-top: 10px;
}

ol li ul,
.box-content-term ol li ul,
.box-content-term ul {
    list-style-type: disc !important;
}

.img-profile {
    border: 3px solid #FB6144;
}

.disabled_link {
    display: inline-block;
    pointer-events: none;
    text-decoration: none;
}

.label-warning-email {
    padding-top: 90px;
}

.pt-top {
    padding-top: 50px !important;
}
/* Style Line */
html,
body {
    height: 100%;
}

body {
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #ffead2;
    overflow: auto !important;
}

a {
    color: #000000;
    text-decoration: none;
    background-color: transparent;
}

    a:hover {
        color: #000000;
        text-decoration: none;
        background-color: transparent;
    }

.a-link-no-underline,
.a-link-no-underline:hover,
.a-link-no-underline:active,
.a-link-no-underline:visited,
.a-link-no-underline:focus {
    text-decoration: none;
}

ol ol, ol ul, ul ol, ul ul {
    padding-left: 15px;
}

.offcanvas-body {
    overflow: hidden;
}

.offcanvas {
    background: #E7F1BF;
}

    .offcanvas ul li a,
    .offcanvas h3,
    .offcanvas p {
        color: #FB6144;
    }

.offcanvas-end {
    width: 270px;
}

.modal-backdrop.show {
    opacity: .3;
}

.index2 {
    position: relative;
    z-index: 2;
}

.index-9 {
    position: relative;
    z-index: 9;
}

.shadow-bottom {
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
}

.shadow-2 {
    box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.2);
}

.bg-top {
    width: 90%;
    position: absolute;
    top: 0;
    left: 5%;
    margin-top: 20px;
    text-align: center;
}

.bg-footer {
    width: 100%;
    position: absolute;
    bottom: 10px;
    margin-bottom: 10px;
    right: 14px;
    text-align: right;
}

.padding-8px {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 0px;
    margin: auto;
    /*max-height: 100%;
    overflow-y: scroll;*/
}

.navbar-toggler:focus {
    box-shadow: none;
}

.form-enter {
    margin: 10px;
    padding: 10px 0;
    position: relative;
    z-index: 9;
}

    .form-enter .fieldset-form {
        border: none;
        padding: 15px;
    }

.form-content {
    background: #f9f9f9;
    margin: 10px;
    padding: 10px 15px;
}

.form-enter .fieldset-form-2 {
    width: 94%;
    margin: 0 auto;
    background-color: #FFF6EE;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

    .form-enter .fieldset-form-2 label {
        color: #009EB6;
    }

    .form-enter .fieldset-form-2 input {
        background-color: #fff;
        border: 1px solid #e4e4e4;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

.form-enter input[type='radio'] {
    accent-color: #00754A;
}

.header-form-receipt {
    background-color: #EF966F;
    color: #fff;
    padding: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.index-2 {
    position: relative;
    z-index: 2;
}

.form-enter .form-control,
.form-enter .form-select {
    background-color: #FFFFFF;
    border: 2px solid #B1B1B1;
    padding: 0px;
    padding-left: 20px;
    padding-bottom: 0px;
    height: 40px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

    .form-enter .form-control:focus,
    .form-enter .form-select:focus {
        background-color: #ECECEC;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .form-enter .form-control.input-date {
        padding: 5px;
    }

.form-control.text-box-bottom-border {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #21409a;
}

.form-enter .form-check .form-check-input[type=checkbox] {
    border-radius: 0.25em;
    width: 18px;
    height: 18px;
    border: 1px solid #707070;
    clear: both;
}

.form-group,
.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

    .form-label-group > input,
    .form-label-group > label {
        height: 3.125rem;
        padding: .75rem;
    }

        .form-label-group > input.form-control {
            padding-left: 15px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            -webkit-box-shadow: 0px 5px 5px 0px rgba(145, 145, 145, 0.5);
            -moz-box-shadow: 0px 5px 5px 0px rgba(145, 145, 145, 0.5);
            box-shadow: 0px 5px 5px 0px rgba(145, 145, 145, 0.5);
        }

.icon-plus {
    color: #FFFFFF;
}

.form-label-group.has-icon-box > input.form-control {
    padding-left: 45px;
}

.form-label-group .has-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    padding-right: 7px;
}

.form-label-group.has-icon-box > label {
    left: 35px;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0px;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #b8b8b8;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder, .form-group input::-webkit-input-placeholder {
    color: #BABCBE;
}

.form-label-group input:-ms-input-placeholder, .form-group input:-ms-input-placeholder {
    color: #BABCBE;
}

.form-label-group input::-ms-input-placeholder, .form-group input::-ms-input-placeholder {
    color: #BABCBE;
}

.form-label-group input::-moz-placeholder, .form-group input::-moz-placeholder {
    color: #BABCBE;
}

.form-label-group input::placeholder, .form-group input::placeholder {
    color: #BABCBE;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

    .form-label-group input:not(:placeholder-shown) ~ label {
        padding-top: .25rem;
        padding-bottom: .25rem;
        font-size: 12px;
        color: #b8b8b8;
    }

.form-group > label, .label-form {
    font-weight: 400;
    font-size: 16px;
    color: #231F20;
}

input[type=checkbox], input[type=radio] {
    margin-right: 5px;
}

.table-reward tr th {
    font-size: 14px;
}

.table-reward {
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 7px;
}

    .table-reward tr td {
        color: #4D4D4D;
        border: none;
        font-size: 13px;
        padding: 8px 10px 4px;
        vertical-align: top;
        line-height: 1.2;
    }

        .table-reward tr td .shop-code {
            color: #919191;
        }

        .table-reward tr td .border-left-1-table {
            border-left: 1px solid #969797;
            padding-left: 5px;
        }

        .table-reward tr td h6 {
            color: #919191;
        }


.text-coupon-num {
    position: absolute;
    top: -5px;
    right: 20px;
    font-size: 22px;
    font-weight: 700;
    color: #E7F1BF;
    background-color: #006241;
    width: 35px;
    height: 35px;
    text-align: center;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
}

.text-wait {
    color: #6C73EB;
}

.text-done {
    color: #2D613F;
}

.text-not-found {
    color: #E8484B;
}

.box-circle-points-1,
.box-circle-points-2,
.box-circle-points-3 {
    margin: 0 auto;
    margin-top: -22px;
    padding: 15px 0;
    text-align: center;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    box-shadow: 0px 2px 5px 0px rgb(203 203 203 / 75%);
    -webkit-box-shadow: 0px 2px 5px 0px rgb(203 203 203 / 75%);
    -moz-box-shadow: 0px 2px 5px 0px rgba(203,203,203,0.75);
}

    .box-circle-points-1 .h1,
    .box-circle-points-2 .h1,
    .box-circle-points-3 .h1 {
        font-size: 60px;
        margin-bottom: 0px;
    }

    .box-circle-points-1 .h6,
    .box-circle-points-2 .h6,
    .box-circle-points-3 .h6 {
        font-size: 14px;
    }

.box-circle-points-1 {
    background-color: #e3f1ff;
}

.box-circle-points-2 {
    background-color: #e7fff8;
}

.box-circle-points-3 {
    background-color: #fff4f4;
}

.close-top-icon {
    position: absolute;
    top: 10px;
    left: 15px;
}

/* Minus Plus */
span.minus, span.plus {
    cursor: pointer;
}

.number {
    text-align: center;
}

.minus, .plus {
    width: 70px;
    height: 30px;
    background: #ffffff;
    border-radius: 4px;
    padding: 2px 5px 8px 5px;
    border: 1px solid #898989;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

input.imput-number {
    height: 40px;
    background-color: transparent;
    width: 70px;
    text-align: center;
    font-size: 26px;
    border: none;
    display: inline-block;
    vertical-align: middle;
}
/* Minus Plus */

/* Custom Pop up */
.popup {
    position: fixed;
    padding: 10px 15px;
    left: 50%;
    margin-left: -150px;
    top: 40%;
    margin-top: -100px;
    background: #FFF;
    color: #231F20;
    z-index: 20;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    width: 300px;
}

#popup1 {
    -webkit-box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.3);
}

.popup .close-popup {
    position: absolute;
    top: 0px;
    right: 15px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.popup h5 {
    font-size: 19px;
    line-height: 1.3;
}

.popup ul li {
    font-size: 13px;
}

.popuphide {
    display: none;
    position: fixed;
    padding: 10px;
    left: 50%;
    margin-left: -150px;
    top: 40%;
    margin-top: -100px;
    background: #FFF;
    z-index: 20;
    border-radius: 5px;
    width: 280px;
}

.form-control-select-popup {
    color: #1E3932;
    width: 100%;
    border: 1px solid #B1B1B1;
    padding: 3px 7px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
    .form-label-group > label {
        display: none;
    }

    .form-label-group input::-ms-input-placeholder {
        color: #777;
    }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-label-group > label {
        display: none;
    }

    .form-label-group input:-ms-input-placeholder {
        color: #777;
    }
}
/* Style Line */

.user-profile {
    margin: 0 auto -50px;
    width: 100px;
}

    .user-profile img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 4px solid #fff;
    }

.box-bg-white {
    width: 80%;
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 70px 15px 35px;
    margin: 0 auto;
}

.box-bg-white-radius-more {
    background-color: #FFFFFF;
    padding: 15px;
    margin: 0;
    border: 1px solid #707070;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

.box-content-term {
    overflow-y: scroll;
    max-height: 470px;
    padding: 20px;
}

    .box-content-term ol li,
    .box-content-term ul li,
    .box-content-term p {
        margin-bottom: 15px;
    }

.border-radius-10 {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mt-100 {
    margin-top: 100px;
}

.form-signin .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 420px;
    z-index: 999;
}

.bg-home {
    background: #ffead2 url("../media/stb_booking_bg@2x.jpg");
    background-position: center top;
    background-size: 100%; /*420px 100%;cover*/
    background-repeat: no-repeat;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.bg-home-enrollment {
    background-color: #eef0ef;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.img-border-5 {
    border: 5px solid #fff;
}

.lh-condensed {
    line-height: 1.25;
}

.break-all {
    word-break: break-all;
}

.text-shadow {
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 1);
}

.card-box img {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.input-style1 {
    color: #080808 !important;
    border: 4px solid #E7EBEF;
    height: 60px;
    padding-left: 20px;
    background-color: #E7EBEF;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -webkit-box-shadow: 1px 2px 2px 0px #c7c7c7, inset 3px 3px 10px 1px #cdcdcd, 0px 3px 9px 1px #c5cbd1;
    box-shadow: 1px 2px 2px 0px #c7c7c7, inset 3px 3px 10px 1px #cdcdcd, 0px 3px 9px 1px #c5cbd1;
}

.line-tab {
    width: 90%;
    height: 3px;
    background-color: #ffffff;
    display: block;
    margin: 0 auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.box-top-left-radius-7 {
    -webkit-border-top-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    border-top-left-radius: 20px;
}

.box-bottom-left-radius-7 {
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-bottom-left-radius: 20px;
}

.border-radius-20 {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.box-top-bottom-right-radius-7 {
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-lerightft-radius: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.box-time-shared {
    background-color: #fddde4;
    -webkit-border-radius: 0px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius: 0px;
    -moz-border-radius-topright: 10px;
    border-radius: 0px;
    border-top-right-radius: 10px;
}

.box-e-coupon-img {
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.btn-register-2 {
    color: #fff;
    font-size: 22px;
    font-family: 'sodo_sanssemibold';
    text-decoration: none;
    min-width: 155px;
    padding: 7px 10px;
    background-color: #00754A;
    border: 2px solid #00754A;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.btn-register-2-dis {
    color: #fff;
    font-size: 22px;
    font-family: 'sodo_sanssemibold';
    text-decoration: none;
    min-width: 155px;
    padding: 7px 10px;
    background-color: #00754A;
    border: 2px solid #00754A;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
}

.btn-sm.btn-register-2 {
    font-size: 0.8rem;
    min-width: 55px;
    width: 100%;
    padding: 5px 5px;
}

.btn-register-2:hover,
.btn-register-2:focus {
    color: #fff;
    outline: none;
    background-color: #00754A;
}

.btn-register-3 {
    color: #fff;
    font-size: 22px;
    font-family: 'sodo_sanssemibold';
    text-decoration: none;
    min-width: 155px;
    padding: 7px 10px;
    background-color: #1E3932;
    border: 2px solid #1E3932;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.btn-sm.btn-register-3 {
    font-size: 0.8rem;
    min-width: 55px;
    width: 100%;
    padding: 5px 10px;
}

.btn-register-3:hover,
.btn-register-3:focus {
    color: #fff;
    outline: none;
    background-color: #1E3932;
}

.btn-register-4 {
    color: #fff;
    font-size: 22px;
    font-family: 'sodo_sanssemibold';
    text-decoration: none;
    min-width: 155px;
    padding: 7px 10px;
    background-color: #606583;
    border: none;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.btn-sm.btn-register-4 {
    font-size: 0.8rem;
    min-width: 55px;
    width: 100%;
    padding: 5px 10px;
}

.btn-register-4:hover,
.btn-register-4:focus {
    color: #fff;
    outline: none;
    background-color: #606583;
}

.btn-register-cancelled {
    color: #fff;
    font-size: 22px;
    font-family: 'sodo_sanssemibold';
    text-decoration: none;
    min-width: 155px;
    padding: 7px 10px;
    background-color: #C80909;
    border: 2px solid #C80909;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

.btn-sm.btn-register-cancelled {
    font-size: 0.8rem;
    min-width: 55px;
    width: 100%;
    padding: 5px 10px;
}

.btn-register-cancelled:hover,
.btn-register-cancelled:focus {
    color: #fff;
    outline: none;
    background-color: #C80909;
}

.btn-register-completed {
    color: #fff;
    font-size: 22px;
    font-family: 'sodo_sanssemibold';
    text-decoration: none;
    min-width: 155px;
    padding: 7px 10px;
    background-color: #B1B1B1;
    border: none;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

.btn-sm.btn-register-completed {
    font-size: 0.8rem;
    min-width: 55px;
    width: 100%;
    padding: 5px 10px;
}

.btn-register-completed:hover,
.btn-register-completed:focus {
    color: #fff;
    outline: none;
    background-color: #B1B1B1;
}

.btn-outline-darkgreen, a.btn-outline-darkgreen {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    width: 100%;
    padding: 6px 5px 6px 12px;
    background-color: #00754A;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    box-shadow: none;
    border: none;
}

    .btn-outline-darkgreen:hover,
    .btn-outline-darkgreen:focus {
        color: #FFFFFF;
        outline: none;
        box-shadow: none;
    }

.btn-outline-white, a.btn-outline-white {
    color: #00754A;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    width: 100%;
    padding: 6px 5px 6px 12px;
    background-color: #FFFFFF;
    border: 1px solid #B1B1B1;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    box-shadow: none;
}

    .btn-outline-white:hover,
    .btn-outline-white:focus {
        color: #00754A;
        outline: none;
        box-shadow: none;
    }

.btn-normal.btn-outline-white, a.btn-normal.btn-outline-white {
    font-size: 16px;
    line-height: 1;
}

.btn-outline-white-2, a.btn-outline-white-2 {
    color: #1E3932;
    font-size: 22px;
    font-family: 'sodo_sanssemibold';
    text-decoration: none;
    min-width: 155px;
    padding: 7px 10px;
    background-color: #FFFFFF;
    border: 2px solid #707070;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

    .btn-outline-white-2:hover,
    .btn-outline-white-2:focus {
        color: #1E3932;
        outline: none;
        box-shadow: none;
    }

.btn-sm.btn-outline-white-2 {
    font-size: 0.8rem;
    min-width: 55px;
    width: 100%;
    padding: 5px 10px;
}

.btn .badge.badge-darkgreen {
    font-size: 10px;
    color: #FFFFFF;
    background-color: #00754A;
    padding: 0.4em 1em 0.2em;
    top: 0px;
    line-height: 1.5;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

.btn .badge.badge-white {
    font-size: 10px;
    color: #00754A;
    background-color: #FFFFFF;
    padding: 0.4em 1em 0.2em;
    top: 0px;
    line-height: 1.5;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

.btn-lock, .btn-lock:focus, .btn-lock:active, .btn-lock:hover {
    color: #B1B1B1;
    background-color: #EFEFEF;
    border: 2px solid #EFEFEF;
}

.card-room-list {
    border: none;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}

.row-card-room-list {
    height: 400px;
    overflow: auto;
}

.row-card-room-list-2 {
    height: 370px;
    overflow: auto;
}

.card-room-list .card-body {
    padding: 0.7rem 1rem;
}

.head-room-name {
    font-family: 'sodo_sansregular';
    font-size: 18px;
    color: #00754A;
}

.row-room-detail {
    margin-top: -5px;
}

.booking-no {
    font-size: 10px;
    color: #1E3932;
}

.booking-number {
    font-size: 15px;
    color: #006241;
}

.room-detail {
    font-size: 14px;
    color: #1E3932;
}

.room-text-detail {
    font-size: 14px;
    color: #006241;
}

.booking-no, .booking-number, .room-detail, .room-text-detail {
    font-family: 'sodo_sanssemibold';
}

.profile {
    border: 4px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(145, 145, 145, 0.5);
    -moz-box-shadow: 0px 5px 5px 0px rgba(145, 145, 145, 0.5);
    box-shadow: 0px 5px 5px 0px rgba(145, 145, 145, 0.5);
}

.bg-qr-code {
    margin: 15px -30px 0;
    background-position: center;
    background-size: contain;
    background-repeat: repeat-y;
}

.bg-box-edit {
    padding: 15px 0 40px;
    margin: 0px -30px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.box-button {
    margin: 0 -30px -48px;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
}

    .dot.active {
        height: 15px;
        width: 15px;
        background-color: #212d69;
        border-radius: 50%;
        display: inline-block;
    }

.tabs-my-rewards .menu-myrewards {
    color: #808080;
    font-size: 14px;
    font-weight: 500;
}

.tabs-my-rewards .active-menu.menu-myrewards {
    color: #0099B1;
    border-bottom: 3px solid #0099B1;
    padding: 0 6px;
}

.tabs-my-rewards .menu-myrewards .line-tab {
    display: none;
}

.box-bg-white ul li,
.box-bg-white-no-radius ul li,
.box-bg-white ol li,
.box-bg-white-no-radius ol li {
    margin-bottom: 15px;
}

.box-footer-profile {
    position: relative;
    text-align: center;
    padding: 15px 0px;
    background-color: #f1f2f2;
    margin: 35px -15px -15px;
    -webkit-border-bottom-right-radius: 45px;
    -webkit-border-bottom-left-radius: 45px;
    -moz-border-radius-bottomright: 45px;
    -moz-border-radius-bottomleft: 45px;
    border-bottom-right-radius: 45px;
    border-bottom-left-radius: 45px;
}

.box-footer-button {
    position: absolute;
    bottom: 25px;
    width: 100%;
    padding: 15px;
}

.img-voucher {
    width: 130px;
    position: absolute;
    right: -45px;
    top: -20px;
}

.bg-before-footer {
    margin: 100px -30px -48px;
}

/* User */
.date-text-select {
    font-size: 18px;
    font-family: 'sodo_sanssemibold';
    color: #00754A;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #B1B1B1;
    padding: 7px;
    line-height: 0.8;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

    .date-text-select .date-text-select-text {
        font-size: 13px;
        font-family: 'sodo_sanssemibold';
        color: #00754A;
    }

    .date-text-select.date-active {
        background-color: #006241;
        border: 1px solid #006241;
        color: #fff;
    }

        .date-text-select.date-active .date-text-select-text {
            color: #fff;
        }

    .date-text-select.date-unactive {
        background-color: #B1B1B1;
        border: 1px solid #B1B1B1;
        color: #898989;
    }
/* User */

/* For the Survey */
.msf_hide {
    display: none;
}

.msf_show {
    display: block;
}

.msf_bullet_o {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

    .msf_bullet_o > div {
        height: 15px;
        width: 15px;
        margin: 20px 5px;
        border-radius: 100px;
        z-index: 2;
    }

.msf_bullet {
    background-color: #FFFFFF;
}

.msf_bullet_active {
    background-color: #212d69 !important;
}
/* For the Survey */

.box-head-e-voucher {
    background-position: center;
    text-align: center;
    background-size: contain;
}

.footer {
    color: #fff;
    background-color: #272d69;
    margin: 0;
}

    .footer p {
        font-size: 12px;
        color: #FFFFFF;
    }

    .footer a {
        font-size: 11px;
        color: #fce500;
    }


@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 961px) and (max-width: 1024px) {
}

@media screen and (min-width: 768px) and (max-width: 960px) {
    .btn-red, .btn-white {
        font-size: 24px;
    }
    /*.form-signin, .form-enter {overflow: hidden;overflow-y: scroll;}*/
}

@media screen and (max-width: 767px) {
    .btn-red, .btn-white {
        font-size: 24px;
    }

    .text-sm-lagre {
        font-size: 18px;
    }

    .text-sm-medium {
        font-size: 16px;
    }

    .text-sm-small {
        font-size: 14px;
    }
    /*.form-signin, .form-enter {overflow: hidden;overflow-y: scroll;}*/

    .head-nav h3 {
        font-size: 22px;
    }
}

@media screen and (max-width: 750px) {
    .form-signin {
        width: 100%;
        max-width: 92%;
    }
}

@media screen and (max-width: 480px) {
    .form-signin {
        width: 100%;
        max-width: 100%;
    }

    .bg-home {
        background-size: 100%;
    }

    .bg-otherpage1 {
        background-size: 100%;
        background-position: top 0px right 0px;
    }

    .form-signin .navbar {
        position: fixed;
        top: 0;
        width: 100%;
        max-width: 100%;
    }

    .head-nav h3 {
        font-size: 20px;
    }

    label {
        font-size: 14px;
    }

    .form-group > label, .label-form {
        font-size: 16px;
    }

    .img-voucher {
        width: 100px;
        position: absolute;
        right: -40px;
        top: 25px;
    }
}

@media screen and (max-width: 420px) {
    .btn-ecoupons {
        font-size: 12px;
        padding: 5px 5px;
        margin-top: -12px;
    }

    .number-e-coupon {
        width: 16px;
        height: 16px;
        font-size: 11px;
    }

    .btn-info-invoice {
        position: absolute;
        top: 13px;
        right: 40px;
    }

    .box-bg-white-radius-more.error-page {
        font-size: 0.9rem;
        padding-left: 0;
        padding-right: 0;
        margin: 10px;
    }
}

@media screen and (max-width: 380px) {
    .btn-info-invoice {
        position: absolute;
        top: 13px;
        right: 20px;
    }

    .table-reward tr th {
        font-size: 12px;
    }

    .table-reward tr td {
        border: none;
        font-size: 11px;
        padding: 2px;
    }

    .text-use-coupon2 {
        margin-top: -2px;
        margin-right: -15px;
        min-height: 40px;
    }

    .tabs-my-rewards .menu-myrewards {
        font-size: 80%;
    }

    .text-datetime-1 {
        font-size: 80%;
    }

    .text-datetime-2 {
        font-size: 100%;
    }

    .text-datetime-3 {
        font-size: 80%;
    }
}

@media screen and (max-width: 330px) {
    .btn-ecoupons {
        font-size: 11px;
        padding: 5px 5px;
        margin-top: -25px;
    }

    .number-e-coupon {
        width: 15px;
        height: 15px;
        font-size: 11px;
    }

    .img-voucher {
        width: 100px;
        position: absolute;
        right: -45px;
        top: 30px;
    }

    .btn-info-invoice {
        position: absolute;
        top: 13px;
        right: 0px;
    }

    .text-tax-invoice {
        font-size: 1.05rem;
    }

    .box-bg-white-radius-more.error-page {
        font-size: 0.8rem;
        padding-left: 0;
        padding-right: 0;
        margin: 10px;
    }

    .col-7.box-e-coupon-info,
    .col-5.box-coupon-img {
        flex: 0 0 auto;
        width: 50%;
    }

    .text-use-coupon2 {
        margin-top: 0;
        min-height: 40px;
    }

    .tabs-my-rewards .menu-myrewards {
        font-size: 65%;
    }

    .text-datetime-1 {
        font-size: 75%;
    }

    .text-datetime-2 {
        font-size: 80%;
    }

    .text-datetime-3 {
        font-size: 75%;
    }

    .p-10px-small-important {
        padding: 7px !important;
    }
}